<template>
  <div class="scene-view" ref="canvassceneBox">

    <div class="emscripten_border">
      <div id="canvas-container" class="scene-container" v-show="canvasVisible">
      <canvas
        id="canvas"
        ref="canvasRef"
        class="canvas-box"
        oncontextmenu="event.preventDefault()"
        tabindex="1"
      ></canvas>
      <img :src="UitemplateBackSvgPic" alt="" @click="goIndex" class="backSvg" srcset="" />
    </div>
    </div>
    <el-progress
      type="circle"
      :percentage="percentage"
      v-show="showpercentage"
    ></el-progress>
  </div>
</template>

<script>
import UitemplateBackSvg from '@/assets/engineBack.svg'
export default {
  data () {
    return {
      UitemplateBackSvgPic: UitemplateBackSvg,
      percentage: 0, // 进度条数值
      showpercentage: true, // 是否显示进度条
      canvasVisible: false, // 是否显示canvas
      value1: 255, // 透明度
      value2: 0, // 金属性
      value3: 0, // 光泽度
      value4: 0, // 自发光
      rgbList: [0, 0, 0]
    }
  },
  mounted () {
    // 初始化的时候必须先获取canvas实例对象，然后才可调用引擎相关接口
    window.BlackHole3D = window.BlackHole3D ? window.BlackHole3D : {}
    window.BlackHole3D.canvas = this.$refs.canvasRef
    if (typeof window.BlackHole3D.RealBIMWeb === 'undefined') {
      // 初始化引擎系统
      window.BlackHole3D = window.CreateBlackHoleWebSDK(window.BlackHole3D)
    }
    this.addEventListener()
  },
  beforeDestroy () {
    this.removeEventListener()
    window.BlackHole3D = null
    sessionStorage.removeItem('model')
  },
  methods: {
    goIndex () {
      this.$router.go(-1)
    },
    addEventListener () {
      document.addEventListener('RESystemReady', this.RESystemReady) // 系统初始化完成回调
      document.addEventListener(
        'RESystemEngineCreated',
        this.RESystemEngineCreated
      ) // 场景初始化完成回调
      document.addEventListener(
        'REDataSetLoadProgress',
        this.REDataSetLoadProgress
      ) // 模型加载进度反馈
      window.addEventListener('beforeunload', this.onBeforeUnload) // 离开页面
      window.addEventListener('resize', this.onResize) // 浏览器窗口大小
    },
    removeEventListener () {
      document.removeEventListener('RESystemReady', this.RESystemReady)
      document.removeEventListener(
        'RESystemEngineCreated',
        this.RESystemEngineCreated
      )
      document.removeEventListener(
        'REDataSetLoadProgress',
        this.REDataSetLoadProgress
      )
      window.removeEventListener('beforeunload', this.onBeforeUnload) // 离开页面
      window.removeEventListener('resize', this.onResize) // 浏览器窗口大小
      window.BlackHole3D = null
    },
    // 场景初始化
    RESystemReady () {
      var sysInfo = new window.BlackHole3D.RESysInfo()
      sysInfo.workerjsPath = './js/RealBIMWeb_Worker.js'
      sysInfo.renderWidth = window.innerWidth
      sysInfo.renderHieght = window.innerHeight
      sysInfo.userName = ''
      sysInfo.passWord = ''
      sysInfo.mainWndName = document.title
      window.BlackHole3D.initEngineSys(sysInfo) // 场景初始化
      window.BlackHole3D.Common.setUseWebCache(true) // 是否允许使用浏览器缓存
    },
    // 场景初始化完成-->模型加载
    RESystemEngineCreated (e) {
      var isSuccess = e.detail.succeed // 场景初始化成功状态
      if (isSuccess) {
        // const dataInfo = this.$route.query.model ? JSON.parse(this.$route.query.model) : null
        const dataInfo = JSON.parse(localStorage.getItem('model'))
        var dataSetList = [
          {
            dataSetId: dataInfo?.projId,
            resourcesAddress: `https://demo.bjblackhole.com/default.aspx?dir=url_res03&path=${dataInfo?.projId}`,
            useTransInfo: true,
            transInfo: [
              [1, 1, 1],
              [0, 0, 0, 1],
              [0.0, 0.0, 0.0]
            ] // 缩放、旋转、平移
          }
        ]
        window.BlackHole3D.Model.loadDataSet(dataSetList, true) // 执行模型加载
        window.BlackHole3D.Common.setMaxResMemMB(5120) // 配置最大显存
        window.BlackHole3D.Common.setExpectMaxInstMemMB(4096) // 期望分配显存`
        window.BlackHole3D.Common.setExpectMaxInstDrawFaceNum(20000000) // 每帧最大渲染面数`
        window.BlackHole3D.Common.setPageLoadLev(2) // 页面调度等级，等级越高，模型加载越快，对硬件要求越高 PC端推荐设为2，手机端设为1。
      } else {
        console.log('===========================  场景初始化 --> 失败！！！')
      }
    },
    // loading 0-100
    REDataSetLoadProgress (e) {
      const percentage = e.detail.progress
      if (percentage === 100) {
        this.canvasVisible = true
        this.showpercentage = false
        window.BlackHole3D.canvas.focus() // 为 <canvas> 元素设置焦点
        const dataInfo = JSON.parse(localStorage.getItem('model'))
        if(dataInfo.projId === 'res_gujianzhu') {
          window.BlackHole3D.BIM.setContourLineClr("", new BlackHole3D.REColor(0,0,0,255));
        }
      }
      this.percentage = percentage
    },
    // 浏览器窗口大小监听
    onResize () {
      window.BlackHole3D.m_re_em_window_width = window.innerWidth
      window.BlackHole3D.m_re_em_window_height = window.innerHeight
    },
    // 离开页面
    onBeforeUnload () {
      if (typeof window.BlackHole3D.releaseEngine !== 'undefined') {
        window.BlackHole3D.releaseEngine() // 资源释放
      }
    }
  }
}
</script>

<style scoped lang="less">
* {
  box-sizing: border-box;
}
.scene-view {
  height: 100%;
  .scene-container {
    width: 100%;
    height: 100%;
    position: relative;
    .canvas-box {
      width: 100%;
      height: 100%;
    }
    .backSvg {
    width: 40px;
    position: absolute;
    left: 60px;
    top: 1%;
  }
    // .slr{
    //   position: absolute;
    //   top: 10px;
    //   left: 120px;
    //   width: 255px;
    // }

  }

  .el-progress {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translateY(-50%);
    transform: translateX(-50%);
  }
}
</style>
